import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_v_card_title = _resolveComponent("v-card-title")
  const _component_v_card = _resolveComponent("v-card")

  return (_openBlock(), _createBlock(_component_v_card, { color: "#dce0e6" }, {
    default: _withCtx(() => [
      _createVNode(_component_v_card_title, {
        class: "text-h4 text-center font-weight-bold",
        style: {"color":"red"}
      }, {
        default: _withCtx(() => [
          _createTextVNode(" SEM INFORMAÇÕES PARA EXIBIR ")
        ]),
        _: 1 /* STABLE */
      })
    ]),
    _: 1 /* STABLE */
  }))
}