<template>
    <v-card color="#dce0e6">
        <v-card-title class="text-h4 text-center font-weight-bold" style="color: red;">
            SEM INFORMAÇÕES PARA EXIBIR
        </v-card-title>
    </v-card>
</template>

<script>
export default {
    name: "sem_infos"
};
</script>