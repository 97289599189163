<template>
  <div>
    <v-app style="background-color: #ECEFF1">
      <navbar />
      <v-main style="background-color: #E0E0E0">
          <router-view />
      </v-main>
    </v-app>
  </div>
</template>

<script>

import Home from './app/pages/Home/index.vue'
import load from './app/producao/modals/load.vue'
import navbar from './app/navbar.vue';
import { registerLicense } from "@syncfusion/ej2-base";
import { useAppCharts } from './stores/appCharts';

export default {
  name: 'App',
  components: {
    navbar,
    load, 
    Home
  },
  beforeCreate: () => {
    registerLicense(
        "Ngo9BigBOggjHTQxAR8/V1NCaF1cWmhIfEx1RHxQdld5ZFRHallYTnNWUj0eQnxTdEFjUX9acH1XR2BZVEZ1Xw=="
      )
  },
}
  

</script>

<style>
@import "../../node_modules/@syncfusion/ej2-base/styles/bootstrap5.css";
@import "../../node_modules/@syncfusion/ej2-buttons/styles/bootstrap5.css";
@import "../../node_modules/@syncfusion/ej2-calendars/styles/bootstrap5.css";
@import "../../node_modules/@syncfusion/ej2-dropdowns/styles/bootstrap5.css";
@import "../../node_modules/@syncfusion/ej2-inputs/styles/bootstrap5.css";
@import "../../node_modules/@syncfusion/ej2-navigations/styles/bootstrap5.css";
@import "../../node_modules/@syncfusion/ej2-popups/styles/bootstrap5.css";
@import "../../node_modules/@syncfusion/ej2-splitbuttons/styles/bootstrap5.css";
@import "../../node_modules/@syncfusion/ej2-vue-grids/styles/bootstrap5.css";
@import '../../node_modules/@syncfusion/ej2-lists/styles/bootstrap5.css';
@import "../../node_modules/@syncfusion/ej2-vue-calendars/styles/bootstrap5.css";
@import '../../node_modules/@syncfusion/ej2-vue-pdfviewer/styles/bootstrap5.css';
@import "../../node_modules/@syncfusion/ej2-vue-pivotview/styles/bootstrap5.css";

html {
  overflow-y: auto !important;
}

#histHoje_content_table > tbody > .e-row > td {
  color: white !important;
  font-weight: bold !important;
  font-size: 16px !important;
  padding: 0 !important;
}

/* Mudar cor da letra para manutenção*/
[data-setup='machine_Setup'] {
  color: black !important;
}

/* Os códigos abaixo servem para definir largura das colunas do grid da pagina detalheApontamento e histórico onde os chields representam as colunas */
.e-grid .e-content #histHoje_content_table col:first-child,
.e-grid .e-headercontent #histHojecolgroup col:first-child {
  width: 80px !important;
}

.e-grid .e-content #histHoje_content_table col:nth-child(2),
.e-grid .e-headercontent #histHojecolgroup col:nth-child(2) {
  width: 100px !important;
}

.e-grid .e-content #histHoje_content_table col:nth-child(n+3):nth-child(-n+5),
.e-grid .e-headercontent #histHojecolgroup col:nth-child(n+3):nth-child(-n+5) {
  width: 155px !important;
}

.e-grid .e-content #histHoje_content_table col:nth-child(6),
.e-grid .e-headercontent #histHojecolgroup col:nth-child(6) {
  width: 550px !important;
}

.e-grid .e-content #histHoje_content_table col:nth-child(n+7):nth-child(-n+9),
.e-grid .e-headercontent #histHojecolgroup col:nth-child(n+7):nth-child(-n+9) {
  width: 160px !important;
}

.e-grid .e-content #histHoje_content_table col:nth-child(10),
.e-grid .e-headercontent #histHojecolgroup col:nth-child(10) {
  width: 200px !important;
}


.e-grid .e-row:hover {
  opacity: 0.8;
}

.e-grid .e-headercell  {
  background-color: #121935 !important;
  /* Cor de fundo desejada */
  color: white !important;
  /* Cor do texto */
}

.e-headertext {
  color: #ffffff !important;
  /* Cor do texto */
}

.e-grid .e-sortfilterdiv {
  font-weight: bold;
  color: #00acc1 !important;
}

.color-tab {
  background-color: #90A4AE !important;
  color: rgb(255, 255, 255);
}

.v-container{
  padding: 0 !important;
}
</style>