import {
  MaquinaSelecionada,
  Apontamentos,
  Apontamento,
  Message,
  Funcionario,
  ApontamentoAtual,
} from "./../app/common/interfaceApp";
import { defineStore } from "pinia";
import { get_cur_turno_by_recurso } from "../app/utils/funcoes"
declare let frappe: any;

export const useAppStore = defineStore({
  id: "app",
  state: (): {
    company: String;
    selectedDates: Object;
    maquina_sel: MaquinaSelecionada;
    apontamento_atual: ApontamentoAtual;
    apontamentos_historico: Apontamentos[];
    view_apontamento_historico: boolean;
    showModalHistorico: boolean;
    showModalObsDetalhes: boolean;
    showModalMotivoDeParada: boolean;
    filtro_apontamentos_faltando_informacoes: Boolean;
    load_actions: Message;
    message: Message;
  } => ({
    company: "GRV Software",
    maquina_sel: {
      id: null,
      leitor: null,
      nome: null,
      operador_padrao: null,
      tipo_apontamento: null,
      apontamentos: [],
      state_sensor: null,
      disponibilidade_diaria: 0,
    },

    apontamento_atual: {
      name: null,
      funcionario: {
        identificacao: null,
        name_entidade: null,
        funcionario: null,
        foto: null,
        nome: null,
        name: null,
      },
      op: null,
      observacao: null,
      dt_inicio: null,
      status: null,
      tipo: null,
      serial: null,
      turno: null,
      desc_motivo_parada: null,
      state_sensor: null,
    },
    apontamentos_historico: [],
    view_apontamento_historico: false,
    showModalHistorico: false,
    showModalObsDetalhes: false,
    showModalMotivoDeParada: false,
    filtro_apontamentos_faltando_informacoes: false,
    selectedDates: [],
    load_actions: {
      showMessage: false,
      message: "",
      color: "secondary",
    },
    message: {
      showMessage: false,
      message: "",
      color: "secondary",
    },
  }),

  getters: {},
  actions: {
    async update_or_unshift_apontamento_historico(apt) {
      // const found = new_apt_hist.find((a) => a.name == apt.name)
      const found = await this.apontamentos_historico.find(
        (e) => e.name == apt.name
      );
      if (!(await found)) {
        this.apontamentos_historico.unshift(apt);
      } else {
        this.apontamentos_historico = this.apontamentos_historico.map((a) =>
          a.name == apt.name ? apt : a
        );
      }

    },
    set_gauge(data: number) {
      if (Object.keys(data).includes(this.maquina_sel.id))
        this.maquina_sel.disponibilidade_diaria = Number(
          data[this.maquina_sel.id].toFixed(2)
        );
    },
    set_tp_parada(apontamento: Apontamentos) {
      var _tp_parada = "";

      if (apontamento.tipo == "Parada") {
        if (apontamento.manutencao == 1 && apontamento.setup == 0) {
          _tp_parada = "Manutenção";
        } else if (apontamento.setup == 1 && apontamento.manutencao == 0) {
          _tp_parada = "Setup";
        } else {
          _tp_parada = "Parada";
        }
      }

      return _tp_parada;
    },
    async reset_app() {
      this.apontamentos_historico = [];
      this.maquina_sel = {
        id: null,
        leitor: null,
        nome: null,
        operador_padrao: null,
        tipo_apontamento: null,
        apontamentos: [],
        state_sensor: null,
        disponibilidade_diaria: 0,
      };

      this.apontamento_atual = {
        name: null,
        funcionario: {
          identificacao: null,
          name_entidade: null,
          funcionario: null,
          foto: null,
          nome: null,
          name: null,
        },
        op: null,
        observacao: null,
        dt_inicio: null,
        status: null,
        tipo: null,
        serial: null,
        turno: null,
        desc_motivo_parada: null,
        state_sensor: null,
      };
    },

    async filtrar_apontamentos(
      faltando_informacoes = false,
      // limit = 15,
      today = 1,
      origem = "desconhecido"
    ) {
      try {
        const res = await frappe.call(
          "nxlite.nx_producao.page.apontamento_iot.controller.get_all_recurso",
          {
            // limit: limit,
            today,
            recurso: this.maquina_sel.id,
            origem: "filtrar_apontamentos_" + origem,
          }
        );
        if (faltando_informacoes && !this.view_apontamento_historico) {
          const apontamentoAberto = res.message[0].apontamentos.find(
            (apontamento: Apontamentos) => apontamento.status === "Aberto"
          );

          if (apontamentoAberto) {
            useAppStore().set_apontamento_realtime(apontamentoAberto);
          }

          useAppStore().apontamentos_historico =
            res.message[0].apontamentos.filter((apontamento: Apontamentos) => {
              if (apontamento.status === "Aberto") {
                return false;
              }
              if (apontamento.tipo === "Produção") {
                return (
                  !apontamento.ordem_de_producao || !apontamento.nome_operador
                );
              }
              if (apontamento.tipo === "Parada") {
                return !apontamento.desc_motivo_parada;
              }

              return true;
            });
        } else {
          useAppStore().apontamentos_historico = [];

          const apontamentoAberto = res.message[0].apontamentos.find(
            (apontamento: Apontamentos) => apontamento.status === "Aberto"
          );

          if (apontamentoAberto) {
            useAppStore().set_apontamento_realtime(apontamentoAberto);

            useAppStore().apontamentos_historico = res.message[0].apontamentos
              .filter(
                (apontamento: Apontamentos) => apontamento.status !== "Aberto"
              )
              .map((apontamento: Apontamentos) => {
                apontamento.motivo_de_parada_manutencao_setup =
                  this.set_tp_parada(apontamento);
                return apontamento;
              });
          }
        }
      } catch (error) {
        throw new Error(" Erro ao filtrar apontamentos " + error);
      }
    },

    set_apontamento_realtime(apontamento: Apontamentos) {
      this.apontamento_atual.name = apontamento.name;
      this.apontamento_atual.funcionario.nome = apontamento.nome_operador;
      this.apontamento_atual.op = apontamento.ordem_de_producao;
      this.apontamento_atual.observacao = apontamento.observacao;
      this.apontamento_atual.dt_inicio = apontamento.dt_inicio;
      this.apontamento_atual.status = apontamento.status;
      this.apontamento_atual.tipo = apontamento.tipo;
      this.apontamento_atual.serial = apontamento.serial;
      this.apontamento_atual.turno = apontamento.turno;
      this.apontamento_atual.desc_motivo_parada =
        apontamento.desc_motivo_parada;
      this.apontamento_atual.state_sensor = apontamento.status_sensor;
      this.apontamento_atual.motivo_de_parada_manutencao_setup =
        apontamento.motivo_de_parada_manutencao_setup;
    },

    selecionar_op(op: any) {
      this.apontamento_atual.op = op.name;
    },

    async selecionar_maquina(maquina: MaquinaSelecionada) {
      

      const turno = await get_cur_turno_by_recurso(maquina.id)
      const disponibilidade_diaria = await frappe.db.get_value(
        "Disponibilidade por turno",
        `${maquina.id}-${
          this.apontamento_atual.turno
        }: ${frappe.datetime.get_today()}`,
        "percentual_producao"
      );

      this.maquina_sel = maquina;

      console.log(this.maquina_sel)
      
      this.maquina_sel.disponibilidade_diaria =
        typeof disponibilidade_diaria.message.percentual_producao === "number"
          ? Number(
              disponibilidade_diaria.message.percentual_producao.toFixed(2)
            )
          : 0;

     

      
      if (Array.isArray(maquina.apontamentos)) {
        const apontamentosMapeados: Apontamento[] = maquina.apontamentos.map(
          (apontamento: Partial<Apontamento>) => {
            return {
              ...apontamento,
              name_entidade: apontamento.name_entidade || null,
              op: apontamento.op || null,
              funcionario: apontamento.funcionario || null,
            } as Apontamento;
          }
        );

        const apontamentoAberto = apontamentosMapeados.find(
          (apontamento: Apontamento) => apontamento.status === "Aberto"
        );

        if (apontamentoAberto) {
          var new_apontamento = true;

          if (this.apontamento_atual.name == apontamentoAberto.name) {
            new_apontamento = false;
          }

          this.set_apontamento_realtime(apontamentoAberto);
          //console.log("apontamentoAberto", apontamentoAberto);
          if (new_apontamento) {
            this.apontamentos_historico = [];
            this.apontamentos_historico = apontamentosMapeados.filter(
              (apontamento: Apontamento) => apontamento.status !== "Aberto"
            );
          }
        } else {
          this.apontamento_atual.state_sensor = maquina.state_sensor;
        }
      }
      console.log('this.apontamentos_historico, após selecionar o maquina', this.apontamentos_historico)
    },

    selecionar_funcionario(funcionario: Funcionario) {
      this.apontamento_atual.funcionario = funcionario;
    },

    async get_all_filter_by_date_apontamento(dt_ini: any, dt_fim: any) {
      try {
        const apt = await frappe.call({
          method:
            "nxlite.nx_producao.page.apontamento_iot.controller.get_all_filter_by_date_apontamento",
          args: {
            start_date: dt_ini,
            end_date: dt_fim,
          },
        });


        interface Apts {
          apontamentos: Apontamento[];
        }

        let apts: any = {};


        if (Array.isArray(apt.message)) {
          const apontamentos = apt.message.map((apontamento: Apontamento) => {
           
            return {
              name: apontamento.name,
              nome_operador: apontamento.nome_operador,
              recurso: apontamento.recurso,
              desc_recurso: apontamento.desc_recurso,
              dt_inicio: apontamento.dt_inicio,
              dt_fim: apontamento.dt_fim,
              ordem_de_producao: apontamento.ordem_de_producao,
              status: apontamento.status,
              observacao: apontamento.observacao,
              tipo: apontamento.tipo,
              serial: apontamento.serial,
              motivo_de_parada: apontamento.motivo_de_parada,
              desc_motivo_parada: apontamento.desc_motivo_parada,
              origem_apontamento: apontamento.origem_apontamento,
              turno: apontamento.turno,
              status_sensor: apontamento.status_sensor,
            };
          });

          return apontamentos;
        } else {
          console.error("apt.message não é um array!");
          return [];
        }
      } catch (error) {
        console.error("Erro ao filtrar apontamentos por data:", error);
        return [];
      }
    },

    persist: { enabled: true },
  },
});
